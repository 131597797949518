dmx.Component('bs5-collapse', {

    initialData: {
        collapsed: true
    },

    attributes: {
        show: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggle: function() {
            this.instance.toggle();
        },

        show: function() {
            this.instance.show();
        },

        hide: function() {
            this.instance.hide();
        }
    },

    events: {
        show: Event,
        shown: Event,
        hide: Event,
        hidden: Event
    },

    render: function(node) {
        this.$node = node;
        this.$parse();

        this.$node.classList.add('collapse');

        this.$node.addEventListener('show.bs.collapse', this.dispatchEvent.bind(this, 'show'));
        this.$node.addEventListener('shown.bs.collapse', this.dispatchEvent.bind(this, 'shown'));
        this.$node.addEventListener('hide.bs.collapse', this.dispatchEvent.bind(this, 'hide'));
        this.$node.addEventListener('hidden.bs.collapse', this.dispatchEvent.bind(this, 'hidden'));

        this.$node.addEventListener('shown.bs.collapse', this.onShown.bind(this));
        this.$node.addEventListener('hidden.bs.collapse', this.onHidden.bind(this));

        var options = { toggle: false };

        if (this.$node.hasAttribute('data-bs-parent')) {
            options.parent = this.$node.getAttribute('data-bs-parent');
        }

        this.instance = new bootstrap.Collapse(this.$node, options);

        this.update({});
    },

    onShown: function() {
        this.set('collapsed', false);
    },

    onHidden: function() {
        this.set('collapsed', true);
    },

    update: function(props) {
        if (props.show != this.props.show) {
            this.instance[this.props.show ? 'show' : 'hide']();
            this.set('collapsed', !this.props.show);
        }
    }

});
